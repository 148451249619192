<script>
import Detail from "./detail";

export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    listBancos: { type: Array, required: true },
    hide: { type: Boolean, required: true }
  },
  components: { Detail },
  data() {
    return {
      titleBody: 'Contas a Pagar ',
      currentPagos: undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { label: "Código", key: "id", sortable: true, tdClass: 'text-right', thClass: 'text-center' },
        { label: "Número", key: "num_doc", sortable: true, thClass: 'text-center' },
        { label: "Fornecedor", key: "cliente_nome", sortable: true, tdClass: 'text-left', thClass: 'text-center' },
        { label: "Historico Padrão", key: "historico_cod", sortable: true, tdClass: 'text-left', thClass: 'text-center' },
        { label: "Valor", key: "valor", sortable: true, tdClass: 'text-left', thClass: 'text-center' },
        { label: "Emissão", key: "emissao", sortable: true, tdClass: 'text-left', thClass: 'text-center' },
        { label: "Data do Pagamento", key: "data_fatura", sortable: true, tdClass: 'text-left', thClass: 'text-center' },
        { label: "Quantidade de parcelas", key: "qtd_parcelas", sortable: true, tdClass: 'text-left', thClass: 'text-center' },
        //{ label: "ID_Empresa", key: "empresa_id", sortable: false, thClass: 'text-center' },
        //{ label: 'Preço', key: 'preco', sortable: false, tdClass: 'text-right', thClass: 'text-center',}
        //   formatter: "formatCurrency"},
        { label: "Ações", key: "acoes", sortable: false, tdClass: 'text-center', thClass: 'text-center' }
      ],
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return (this.listBancos.length) ? this.listBancos.length : 0;
    },
    clonefoot() {
      return (this.listBancos.length || this.listBancos.length > 10) ? true : false;
    },
  },
  mounted() {
    this.$emit("newTitle", this.titleBody);
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // eslint-disable-next-line no-unused-vars
    formatCurrency(value, key, item) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    setBanco(value) {
      this.currentPagos = value;
      this.$refs['modal-banco'].show();
    },
    setEdit(value) {
      this.setBanco(value);
      this.$emit('edit', value);
    },
    setDelete(value) {
      this.$emit('doDelete', value);
    },
  },
}
</script>

<template>
  <div class="card-body" v-if="hide">
    <div class="row mt-4">
      <!-- Start Limit -->
      <div class="col-sm-12 col-md-6">
        <div id="produto-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Exibir&nbsp;
            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;registros
          </label>
        </div>
      </div>
      <!-- End Limit -->
      <!-- Start Search -->
      <div class="col-sm-12 col-md-6">
        <div id="produto-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Pesquisar:
            <b-form-input v-model="filter" type="search" placeholder="Pesquisar..."
              class="form-control form-control-sm ml-2"></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Start Table -->
    <div class="row mt-4">
      <div v-if="hide" class="table-responsive mb-0">
        <b-table :items="listBancos" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
          @filtered="onFiltered" :hover=true :foot-clone=clonefoot>
          <template #cell(valor)="row">
            {{ (formatterCurrBR(row.item.valor)) }}
          </template>
          <template #cell(acoes)="row">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown v-bind:id="'dropdown-' + row.item.id" class="m-md-2">
              <template #button-content>
                <i data-v-6289eca4="" class="bx ri-menu-line"></i>
              </template>
              <b-dropdown-item v-b-modal.modal-banco @click="setBanco(row.item)"><i class="bx ri-eraser-fill"></i>
                Visualizar</b-dropdown-item>
              <b-dropdown-item @click="setEdit(row.item)"><i class="bx ri-edit-2-line"></i> Alterar</b-dropdown-item>
              <b-dropdown-item @click="setDelete(row.item)"><i class="bx ri-eraser-fill"></i> Excluir</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>
    </div>
    <!-- End Table -->
    <!-- Start Pagination -->
    <div v-if="hide" class="row mt-4">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Pagination -->
    <!-- Start Modal Produto -->
    <b-modal v-if="hide" hide-footer id="modal-banco" ref="modal-banco" :title="'Detalhes do Banco'"
      title-class="font-18">
      <Detail :pagos="currentPagos" />
    </b-modal>
    <!-- End Modal Produto -->
  </div>
</template>