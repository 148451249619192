<script>
import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";

export default {
  components: { Multiselect },
  props: {
    historicos: { type: Array },
    clientes: { type: Array },
    resultados: { type: Array },
    centtros: { type: Array },
    tiposPagamentos: { type: Array }
  },
  data() {
    return {
      loader: { get: false },
      titleBody: 'Incluir Conta a Pagar',
      res: null,
      cent: null,
      banco: {
        banco_nome: null,
        banco_codigo: null,
        banco_digito: null,
        banco_maxnossoNum: null,
        banco_orientacoes: null
      },
      Conta_pagar: {
        historico_cod: 1,
        cliente_nome: null,
        cliente_cod: 1,
        tipo_pagamento: 1,
        num_doc: 1,
        valor: 1,
        qtd_parcelas: 1,
        emissao: '2023-03-03',
        data_parcela: '2023-03-03',
        data_fatura: '2023-03-03',
        cod_cupom: 1,
        credito: null,
        debito: null,
        obs: null,
      },
      pagamentos: [
      ],
      parcelas_vet: [

      ],
      plano_contas: {
        cod: null,
        ccid: null,
        debito: null,
        crid: null,
        credito: null,
        valor: null,
        ativo: null,
      },
      parcelas: {
        numero: 1,
        tipo_pagamento: 1,
        historico: "histrocio",
        emissao: "2023-04-02",
        vencimento: "2023-04-02",
        valor: 12,
        juros: 12,
        multa: 12,
        duplicata: 12,
        pago: 1,
        pago_em: '2023-04-02',
        banco_cheque: 0
      },
      fields: [
        { label: "Numero", key: "numero", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Tipo de Pagamento", key: "tipo_pagamento", tdClass: 'text-left', thClass: 'text-center' },
        { label: "Historico Padrão", key: "historico", tdClass: 'text-left', thClass: 'text-center' },
        { label: "Emissão", key: "emissão", tdClass: 'text-right', thClass: 'text-center', formatter: "formatterCurrBR" },
        { label: "Vencimento", key: "vencimento", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Valor", key: "valor", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Juros", key: "juros", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Multa", key: "multa", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Total Duplicata", key: "duplicata", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Pago", key: "pago", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Pago em", key: "pago_em", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Banco Cheque", key: "banco_cheque", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ],
      get_historicos: [],
      historicos_1: null,
      historicos_cod: null,
      cliente_1: null,
      cliente_cod: null,
      get_clientes: [],
      tp_pagamento: null,
      debito:null,
      credito:null,
    }
  },
  created() {
    this.get_historicos = this.historicos
    this.get_clientes = this.clientes
    this.res = this.resultados
    this.cent = this.centtros
    this.pagamentos = this.tiposPagamentos
  },
  validations() {
    return {
      banco: {
        banco_nome: { required },
        banco_codigo: { required },
      }
    }
  },
  mounted() {
    this.setNewTitle();
  },
  methods: {
    select_hist_cod(objeto) {
      let objeto1 = objeto
      console.log(objeto1)
      //this.receber.historico_cod = objeto1.id
      //this.historicos_cod = objeto1
      this.Conta_pagar.historico_cod = objeto1.id
      //this.Conta_pagar.obj = objeto1

    },
    // historico_cod: 1,
    //     cliente_nome: null,
    //     cliente_cod: 1,
    //     tipo_pagamento: 1,
    //     num_doc: 1,
    //     valor: 1,
    //     qtd_parcelas: 1,
    //     emissao: '2023-03-03',
    //     data_parcela: '2023-03-03',
    //     data_fatura: '2023-03-03',
    //     cod_cupom: 1,
    //     credito: null,
    //     debito: null,
    //     obs: null,
    select_cli_nome(objeto) {
      let objeto1 = objeto
      //this.receber.cliente = objeto1.pes_fantasia
      // this.cliente_1 = objeto1
      this.Conta_pagar.cliente_nome = objeto1.pes_fantasia
      this.Conta_pagar.cliente_cod = objeto1.id
    },
    formapagamento(objeto){
      let objeto1 = objeto
      //this.receber.cliente = objeto1.pes_fantasia
      // this.cliente_1 = objeto1
      this.Conta_pagar.tipo_pagamento = objeto1.id
      
    },
    selecionadebito(objeto){
      let objeto1 = objeto
      //this.receber.cliente = objeto1.pes_fantasia
      // this.cliente_1 = objeto1
      this.Conta_pagar.debito = objeto1.id
    },
    selecionacredito(objeto){
      let objeto1 = objeto
      //this.receber.cliente = objeto1.pes_fantasia
      // this.cliente_1 = objeto1
      this.Conta_pagar.credito = objeto1.id
    },
    add_parcelas(e) {
      e.preventDefault();
      this.verifica = true
      let objeto = { ...this.parcelas }
      this.parcelas_vet.push(objeto)
    },
    customLabelComposicao({ id, identificacao }) {
      return `${id} – ${identificacao}`
    },

    customLabelHistorico({ id, descricao }) {
      return `${id} – ${descricao}`
    },
    customLabelPagamento({ id, tiponome }) {
      return `${id} – ${tiponome}`
    },

    customLabelCliente({ id, pes_fantasia }) {
      return `${id} – ${pes_fantasia}`
    },
    remove() {
      this.parcelas_vet.pop()
    },
    formatInput_valor_info() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.Conta_pagar.valor.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.Conta_pagar.valor = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_valor_plano_contas() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.plano_contas.valor.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.plano_contas.valor = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_valor_parcelas() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.parcelas.valor.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.parcelas.valor = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_juros_parcelas() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.parcelas.juros.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.parcelas.juros = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_multa_parcelas() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.parcelas.multa.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.parcelas.multa = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    formatInput_total_duplicata_parcelas() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.parcelas.duplicata.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.parcelas.duplicata = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },

    setPost() {
      this.$emit('doPost', this.Conta_pagar);
    },

  },
}
</script>
<template>
  <div class="card">
    <div class="card-body">
      <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success text-center"></i>
      <form id="form-tipo-movimento" role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-body>
            <h4>Contas a Pagar</h4>
            <div class="form-row form-group" role="group">
              <!-- <div class="col-sm-12 col-md-2">
                <div class="form-group">
                  <label for="tipo_nome_id" class="col-form-label">historico Padrão</label>
                  <div class="col">
                    <multiselect label="id" :options="get_historicos" v-model="historicos_cod"
                      @select="select_hist_nome($event)">
                    </multiselect>
                  </div>
                </div>
              </div> -->
              <div class="col-sm-12 col-md-10">
                <div class="form-group">
                  <label for="tipo_nome_id" class="col-form-label">historico Padrão</label>
                  <div class="col">
                    <!-- <multiselect label="descricao" :options="get_historicos" v-model="historicos_1"
                      @select="select_hist_cod($event)">
                    </multiselect> -->
                    <multiselect v-model="historicos_1" placeholder="Selecione" label="descricao" track-by="descricao"
                      :options="get_historicos" :option-height="104" :custom-label="customLabelHistorico"
                      :show-labels="false" deselect-label="ENTER para remover" select-label="Pressione ENTER"
                      @select="select_hist_cod($event)">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.descricao }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.descricao }}</span>
                        </div>
                      </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              <!-- <div class="col-sm-12 col-md-2">
                <div class="form-group">
                  <label for="tipo_banco_codigo" class="col-form-label">Cliente</label>
                  <div class="col">
                    <multiselect label="id" :options="get_clientes" v-model="cliente_cod"
                      @select="select_cli_cod($event)">
                    </multiselect>
                  </div>
                </div>
              </div> -->
              <div class="col-sm-12 col-md-10">
                <div class="form-group">
                  <label for="tipo_banco_codigo" class="col-form-label">Cliente</label>
                  <div class="col">
                    <!-- <multiselect label="pes_fantasia" :options="get_clientes" v-model="cliente_cod"
                      @select="select_cli_nome($event)">
                    </multiselect> -->
                    <multiselect v-model="cliente_cod" placeholder="Selecione" label="pes_fantasia"
                      track-by="pes_fantasia" :options="get_clientes" :option-height="104"
                      :custom-label="customLabelCliente" :show-labels="false" deselect-label="ENTER para remover"
                      select-label="Pressione ENTER" @select="select_cli_nome($event)">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.pes_fantasia }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.pes_fantasia }}</span>
                        </div>
                      </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_digito" class="col-form-label">Forma de pagamento</label>
                  <div class="col">
                    <!-- <Multiselect :options="pagamentos" label="tiponome">

                    </Multiselect> -->
                    <multiselect v-model="tp_pagamento" placeholder="Selecione" label="tiponome" track-by="tiponome"
                      :options="pagamentos" :option-height="104" :custom-label="customLabelPagamento" :show-labels="false"
                      deselect-label="ENTER para remover" select-label="Pressione ENTER" @select="formapagamento($event)">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.tiponome }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.tiponome }}</span>
                        </div>
                      </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_digito" class="col-form-label">Número do Documento</label>
                  <div class="col">
                    <input class="form-control text-left" type="text" placeholder="digite o número do documento"
                      id="codigo">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_digito" class="col-form-label">Valor</label>
                  <div class="col">
                    <input v-model="Conta_pagar.valor" class="form-control text-left" type="text"
                      placeholder="Digite o Valor" id="codigo" @input="formatInput_valor_info()">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_digito" class="col-form-label">Quantidade de Parcelas</label>
                  <div class="col">
                    <input class="form-control text-left" type="number" min="0"
                      placeholder="digite a Quantidade de Parcelas" id="codigo">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_num" class="col-form-label">Emissão:</label>
                  <div class="col">
                    <input id="searchCompras-fromData" type="datetime-local" class="form-control"
                      placeholder="dd/mm/aaaa hh:mm" />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_num" class="col-form-label">Data da Primeira Parcela:</label>
                  <div class="col">
                    <input id="searchCompras-fromData" type="datetime-local" class="form-control"
                      placeholder="dd/mm/aaaa hh:mm" />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_num" class="col-form-label">Débito:</label>
                  <div class="col">
                    <!-- <Multiselect v-model="Conta_pagar.debito" :options="cent" label="identificacao">

                    </Multiselect> -->
                    <multiselect v-model="debito" placeholder="Selecione" label="identificacao"
                      track-by="identificacao" :options="cent" :option-height="104" :custom-label="customLabelComposicao"
                      :show-labels="false" deselect-label="ENTER para remover" select-label="Pressione ENTER"
                      @select="selecionadebito($event)">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.identificacao }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.identificacao }}</span>
                        </div>
                      </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_banco_num" class="col-form-label">Crédito:</label>
                  <div class="col">
                    <!-- <Multiselect v-model="Conta_pagar.credito" :options="res" label="identificacao">

                    </Multiselect> -->
                    <multiselect v-model="credito" placeholder="Selecione" label="identificacao"
                      track-by="identificacao" :options="res" :option-height="104" :custom-label="customLabelComposicao"
                      :show-labels="false" deselect-label="ENTER para remover" select-label="Pressione ENTER"
                      @select="selecionacredito($event)">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.identificacao }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.id }} - {{
                            props.option.identificacao }}</span>
                        </div>
                      </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-12">
                <div class="form-group">
                  <label for="tipo_banco_orient" class="col-form-label">Observações</label>
                  <div class="col">
                    <!-- aqui  -->
                    <textarea v-model="Conta_pagar.obs" name="" id="" cols="30" rows="10" class="form-control">

                    </textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion" role="tablist">
              <form role="form" class="form-horizontal">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.pesquisar variant="light">Parcelas:</b-button>
                  </b-card-header>
                  <!-- visible -->
                  <b-collapse id="pesquisar" accordion="pesquisar" role="tabpanel">
                    <b-card-body>
                      <label for="cartao_in_id" class="col-form-label">Parcelas:</label>
                      <div class="col">
                        <div>
                          <b-table striped hover :items="parcelas_vet" responsive id="contas-receber" :fields="fields">
                          </b-table>
                        </div>
                        <b-table-simple responsive>
                          <b-thead id="b-head">
                            <b-tr>
                              <b-th class="b-th">Numero</b-th>
                              <b-th class="b-th">Tipo de Pagamento</b-th>
                              <b-th class="b-th">Historico Padrão</b-th>
                              <b-th class="b-th">Emissão</b-th>
                              <b-th class="b-th">Vencimento</b-th>
                              <b-th class="b-th">Valor</b-th>
                              <b-th class="b-th">Juros</b-th>
                              <b-th class="b-th">Multa</b-th>
                              <b-th class="b-th">Total Duplicata</b-th>
                              <b-th class="b-th">Pago</b-th>
                              <b-th class="b-th">Pago em</b-th>
                              <b-th class="b-th">Banco Cheque</b-th>
                              <b-th class="b-th">&nbsp;</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>

                            <b-tr>
                              <b-td>
                                <input v-model="parcelas.numero" id="espaco" type="text" class="form-control text-left">
                              </b-td>
                              <b-td>
                                <div class="custom-multiselect-wrapper">
                                  <multiselect v-model="tp_pagamento" placeholder="Selecione" label="tiponome"
                                    track-by="tiponome" :options="pagamentos" :option-height="104"
                                    :custom-label="customLabelPagamento" :show-labels="false"
                                    deselect-label="ENTER para remover" select-label="Pressione ENTER">
                                    <template slot="singleLabel" slot-scope="props">
                                      <span class="option__desc">
                                        <span class="option__title">{{ props.option.id }} - {{
                                          props.option.tiponome }}</span>
                                      </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                      <div class="option__desc">
                                        <span class="option__title">{{ props.option.id }} - {{
                                          props.option.tiponome }}</span>
                                      </div>
                                    </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                                  </multiselect>
                                </div>
                              </b-td>
                              <b-td>
                                <div class="custom-multiselect-wrapper">
                                  <multiselect v-model="historicos_1" placeholder="Selecione" label="descricao"
                                    track-by="descricao" :options="get_historicos" :option-height="104"
                                    :custom-label="customLabelHistorico" :show-labels="false"
                                    deselect-label="ENTER para remover" select-label="Pressione ENTER"
                                    @select="select_hist_cod($event)">
                                    <template slot="singleLabel" slot-scope="props">
                                      <span class="option__desc">
                                        <span class="option__title">{{ props.option.id }} - {{
                                          props.option.descricao }}</span>
                                      </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                      <div class="option__desc">
                                        <span class="option__title">{{ props.option.id }} - {{
                                          props.option.descricao }}</span>
                                      </div>
                                    </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                                  </multiselect>
                                </div>
                              </b-td>
                              <b-td>
                                <input v-model="parcelas.emissao" id="searchCompras-fromData" type="datetime-local"
                                  class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                              </b-td>
                              <b-td>
                                <input v-model="parcelas.vencimento" id="searchCompras-fromData" type="datetime-local"
                                  class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                              </b-td>
                              <b-td>
                                <input v-model="parcelas.valor" id="espaco" type="text" class="form-control text-right"
                                  @input="formatInput_valor_parcelas()">
                              </b-td>
                              <b-td>

                                <input @input="formatInput_juros_parcelas()" v-model="parcelas.juros" id="espaco"
                                  type="text" class="form-control text-right">
                              </b-td>
                              <b-td>

                                <input @input="formatInput_multa_parcelas()" v-model="parcelas.multa" id="espaco"
                                  type="text" class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input @input="formatInput_total_duplicata_parcelas()" v-model="parcelas.duplicata"
                                  id="espaco" type="text" class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input v-model="parcelas.pago" id="espaco" type="text" class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input v-model="parcelas.pago_em" id="searchCompras-fromData" type="datetime-local"
                                  class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                              </b-td>
                              <b-td>
                                <input v-model="parcelas.banco_cheque" id="espaco" type="text"
                                  class="form-control text-right">
                              </b-td>
                              <b-td>
                                <button class="btn btn-secondary" @click="add_parcelas($event)">Incluir</button>
                              </b-td>
                              <b-td>
                                <button class="btn btn-secondary" @click.prevent="remove($event)">Excluir</button>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </b-card-body>
                  </b-collapse>
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.pesquisar1 variant="light">Plano de Contas:</b-button>
                  </b-card-header>
                  <!-- visible -->
                  <b-collapse id="pesquisar1" accordion="pesquisar" role="tabpanel">
                    <b-card-body>
                      <div class="form-row form-group" role="group">
                        <div class="col-sm-12 col-md-12" id="desc_ID">
                          <div class="form-group">
                            <label for="cartao_in_id" class="col-form-label">&nbsp;</label>
                            <div class="col">
                              <b-table-simple responsive>
                                <b-thead id="b-head">
                                  <b-tr>
                                    <b-th class="b-th">Código</b-th>
                                    <b-th class="b-th">C.C ID</b-th>
                                    <b-th class="b-th">Débito</b-th>
                                    <b-th class="b-th">C.R Id</b-th>
                                    <b-th class="b-th">Crédito</b-th>
                                    <b-th class="b-th">Valor</b-th>
                                    <b-th class="b-th">Ativo</b-th>
                                    <b-th class="b-th">&nbsp;</b-th>
                                  </b-tr>
                                </b-thead>
                                <b-tbody>
                                  <b-tr>
                                    <b-td>
                                      <input v-model="plano_contas.cod" id="espaco" type="text"
                                        class="form-control text-left">
                                    </b-td>
                                    <b-td>
                                      <input v-model="plano_contas.ccid" id="espaco" type="text"
                                        class="form-control text-left">
                                    </b-td>
                                    <b-td id="espaco">
                                      <div class="custom-multiselect-wrapper">
                                        <!-- <multiselect :options="res" label="identificacao">
                                        </multiselect> -->
                                        <multiselect v-model="plano_contas.debito" placeholder="Selecione"
                                          label="identificacao" track-by="identificacao" :options="res"
                                          :option-height="104" :custom-label="customLabelComposicao" :show-labels="false"
                                          deselect-label="ENTER para remover" select-label="Pressione ENTER">
                                          <template slot="singleLabel" slot-scope="props">
                                            <span class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </span>
                                          </template>
                                          <template slot="option" slot-scope="props">
                                            <div class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </div>
                                          </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                                        </multiselect>
                                      </div>
                                    </b-td>
                                    <b-td>
                                      <input v-model="plano_contas.crid" id="espaco" type="text"
                                        class="form-control text-right">
                                    </b-td>
                                    <b-td>
                                      <div class="custom-multiselect-wrapper">
                                        <!-- <multiselect  :options="cent" label="identificacao">
                                        </multiselect> -->

                                        <multiselect v-model="plano_contas.credito" placeholder="Selecione"
                                          label="identificacao" track-by="identificacao" :options="cent"
                                          :option-height="104" :custom-label="customLabelComposicao" :show-labels="false"
                                          deselect-label="ENTER para remover" select-label="Pressione ENTER">
                                          <template slot="singleLabel" slot-scope="props">
                                            <span class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </span>
                                          </template>
                                          <template slot="option" slot-scope="props">
                                            <div class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </div>
                                          </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                                        </multiselect>
                                      </div>
                                    </b-td>
                                    <b-td>
                                      <input @input="formatInput_valor_plano_contas()" v-model="plano_contas.valor"
                                        id="espaco" type="text" class="form-control text-right">
                                    </b-td>
                                    <b-td>
                                      <input v-model="plano_contas.ativo" id="espaco" type="text"
                                        class="form-control text-right">
                                    </b-td>
                                    <b-td>
                                      <button class="btn btn-secondary">Incluir</button>
                                    </b-td>
                                  </b-tr>
                                </b-tbody>
                              </b-table-simple>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </form>
            </div>
          </b-card-body>
        </b-card>
      </form>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click="setPost">Gravar</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
#espaco {
  width: 100px;
}

.custom-multiselect-wrapper {
  width: 200px;
  /* Ajuste a largura conforme necessário */
}

/* Ajuste para a tabela responsiva */
.b-table-simple__table-wrapper {
  overflow-x: auto;
}
</style>